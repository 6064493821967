import React from "react";
import TextFormatter from "../../../utils/TextFormatter";
import CustomLink from "../../shared/custom-link/CustomLink";

type ReadMoreProps = {
  data: { header: string }[]
}

const ReadMore = ({data}: ReadMoreProps) => {
  if (data.length === 0) {
    return null;
  }

  return(
    <div className={'flex flex-col border border-neutrals-80 p-7 rounded w-full'}>
      <p className={'font-semibold text-black uppercase font-lg mb-2.5'}>Przeczytaj również</p>
      <ul className={'list-disc'}>
        {data.map((article, index) => {
          const slug = TextFormatter.slugify(article.header);
          return (
            <CustomLink key={index} isInternal to={`/blog/${slug}`}>
              <li className={'ml-5 mb-2'}>
                <span>{article.header}</span>
              </li>
            </CustomLink>
          );
        })}
      </ul>
    </div>
  )
}

export default ReadMore;

import React from 'react';
import {graphql, HeadProps} from 'gatsby';

import {SiteMetadataConfigProps} from "../types/metadata-config-props.type";
import Layout from "../components/Layout";
import {PageContextType} from "../types/page-context.type";
import BlogArticleComponent, {BlogArticleProps} from "../components/blog/blog-article/BlogArticle";
import StructuredData from '../assets/structured-data';
import TextFormatter from '../utils/TextFormatter';
import {SEO} from '../types/seo/SEO';
import {BannerProps} from "../components/blog/blog-article/banner/Banner";
import {BannerContextProvider} from "../components/blog/blog-article/banner/BannerHandlerContext";

const BlogArticle = ({data, pageContext}: HeadProps<BlogArticleProps & SiteMetadataConfigProps & BannerProps, PageContextType>) => {
  return (
    <Layout crumbs={pageContext.breadcrumb.crumbs} crumbLabel={data.strapiBlogArticle.header}>
      <BannerContextProvider>
        <BlogArticleComponent article={data}/>
      </BannerContextProvider>
    </Layout>
  )
}

export default BlogArticle;

export const Head = ({data}: HeadProps<BlogArticleProps & SiteMetadataConfigProps>) => {
  const siteUrl = data.site.siteMetadata.siteUrl;
  const SEOData = SEO.create(data.strapiBlogArticle.seo);
  const {publishedAt, updatedAt, image, keywords, header, title} = data.strapiBlogArticle;
  const username = data.strapiBlogArticle.users_permissions_user?.username || '';
  const slug = TextFormatter.slugify(header);
  const structuredData = SEOData.structuredData ? [SEOData.structuredData] : null;
  const fullUrl = siteUrl + '/blog' + '/' + slug + '/';
  const imageUrl = SEOData.metaImage ? SEOData.metaImage.localFile.url : image.localFile.url;

  return (
    <>
      <title>{SEOData.metaTitle || header}</title>
      <meta name="description" content={SEOData.metaDescription || title}/>
      <meta name="keywords" content={SEOData.keywords || keywords}/>
      <meta name="robots" content="index, follow"/>
      <meta name="theme-color" content="#24B25B"/>
      {/* OGP tags*/}
      <meta property="og:description" content={SEOData.facebookSocialNetwork.description || SEOData.metaDescription || title}/>
      <meta property="og:title" content={SEOData.facebookSocialNetwork.title || SEOData.metaTitle || header} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:image" content={imageUrl} />
      {/* twitter */}
      <meta name="twitter:description" content={SEOData.twitterSocialNetwork.description || SEOData.metaDescription || title}/>
      <meta name="twitter:title" content={SEOData.twitterSocialNetwork.title || SEOData.metaTitle || header}/>
      <meta name="twitter:image" content={imageUrl}/>
      <meta name="twitter:card" content="summary_large_image"/>
      {StructuredData([
        {
          name: 'Blog',
          item: siteUrl + '/blog' + '/'
        },
        {
          name: header,
          item: fullUrl
        }
      ],  structuredData || [
        {
          "@type": "BlogPosting",
          "headline": header,
          "image": imageUrl,
          "datePublished": publishedAt,
          "dateModified": updatedAt,
          "author": [
            {
              "@type": "Person",
              "name": username,
            }
          ],
          "keywords": keywords,
        },
      ])}
    </>
  )
}
export const query = graphql`
  query ($id: String, $regexp: String!){
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
  strapiBanner {
    key_tags
    title
    description
  }
    allStrapiBlogArticle(filter: {id: {regex: $regexp}}){
      nodes {
        header
        title
        publishedAt
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FIXED
                placeholder: BLURRED
                width: 320
                height: 205
                quality: 100
              )
            }
          }
        }
        users_permissions_user {
          username
          userAvatar {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  width: 30
                  height: 30
                  quality: 100
                )
              }
            }
          }
        }
      }
    }
    strapiBlogArticle(id: { eq: $id }) {
      seo {
        metaSocial {
          title
          description
          socialNetwork
        }
        keywords
        metaDescription
        metaTitle
        metaImage {
          alternativeText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
      readMore {
        header
      }
      header
      title
      keywords
      description {
        data {
          description
        }
      }
      publishedAt
      updatedAt
      image {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 90, height: 450, width: 1114)
          }
        }
      }
      users_permissions_user {
        username
        userAvatar {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                height: 30
                width: 30
              )
            }
          }
        }
      }
    }
  }
`

import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import CustomLink from "../shared/custom-link/CustomLink";
import TextFormatter from "../../utils/TextFormatter";

type GetAllHeadersResponse = {
  allStrapiBlogArticle: {
    nodes: { header: string }[]
  }
}

const ArticlePaginator = ({header}: {header: string}) => {
  const {allStrapiBlogArticle: {nodes}} = useStaticQuery<GetAllHeadersResponse>(query);

  if (nodes.length <= 1) {
    return null;
  }

  const articleIndex = nodes.findIndex(n => n.header === header);
  const first = articleIndex === 0;
  const last = articleIndex === nodes.length - 1;

  if (first) {
    const nextSlug = TextFormatter.slugify(nodes[articleIndex + 1].header);
    return (
      <div className={'flex justify-end'}>
        <CustomLink isInternal to={`/blog/${nextSlug}`}>
          <span className={'uppercase font-head text-black text-sm font-semibold'}>Następny wpis</span>
        </CustomLink>
      </div>
    )
  }

  if (last) {
    const prevSlug = TextFormatter.slugify(nodes[articleIndex - 1].header);
    return(
      <div className={'flex justify-start'}>
        <CustomLink isInternal to={`/blog/${prevSlug}`}>
          <span className={'uppercase font-head text-black text-sm font-semibold'}>Poprzedni wpis</span>
        </CustomLink>
      </div>
    )
  }

  const prevSlug = TextFormatter.slugify(nodes[articleIndex - 1].header);
  const nextSlug = TextFormatter.slugify(nodes[articleIndex + 1].header);

  return(
    <div className={'flex justify-between'}>
      <CustomLink isInternal to={`/blog/${prevSlug}`}>
        <span className={'uppercase font-head text-black text-sm font-semibold'}>Poprzedni wpis</span>
      </CustomLink>

      <CustomLink isInternal to={`/blog/${nextSlug}`}>
        <span className={'uppercase font-head text-black text-sm font-semibold'}>Następny wpis</span>
      </CustomLink>
    </div>
  )
}

export default ArticlePaginator;

export const query = graphql`
  query {
    allStrapiBlogArticle(sort: {fields: publishedAt, order: DESC}) {
      nodes {
        header
      }
    }
  }
`

import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import ArticleCard, {ArticleCardProps} from "./article-card/ArticleCard";

type PopularArticlesProps = {
  allStrapiBlogArticle: {
    nodes: ArticleCardProps[]
  }
}

const PopularArticles = ({articles}: { articles: ArticleCardProps[] }) => {
  if (articles.length === 0) {
    return null;
  }

  return(
    <div className={'flex flex-col items-center'}>
      <p className={'text-3xl sm:text-4xl font-head text-black mb-10 px-5'}>Popularne wpisy na blogu</p>
      <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-10 gap-x-5 max-w-[1040px] px-5 sm:px-0'}>
        {articles.map((article, index) => {
          return(
            <ArticleCard article={article} key={index}/>
          )
        })}
      </div>
    </div>
  )
}

export default PopularArticles;

import React from "react";

/**
 *
 * @param tags - comma seperated tags.
 */
const Tags = ({tags}: {tags?: string}) => {
  if (!tags) return null;

  return(
    <div className={'flex flex-row flex-wrap gap-2.5'}>
      {tags.split(', ').map((tag, index) => {
        return(
          <span key={index} className={'px-3 py-2 bg-neutrals-80 rounded'}>{tag}</span>
        )
      })}
    </div>
  )
}

export default Tags;

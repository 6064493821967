import {MetaSocial, SEOInterface} from './SEO.type';
import {GatsbyImageCustomType} from '../gatsby-image.type';

export class SEO implements SEOInterface {
  canonicalURL: string;
  metaDescription: string;
  metaImage: GatsbyImageCustomType;
  metaTitle: string;
  metaViewport: string;
  metaSocial?: MetaSocial[] = [];
  keywords?: string;
  metaRobots?: string;
  structuredData?: object;

  facebookSocialNetwork = new MetaSocial();
  twitterSocialNetwork = new MetaSocial();

  static create(data: SEOInterface | undefined): SEO {
    if (!data) {
      return new SEO();
    }

    const seo = new SEO();

    if (data.metaSocial) {
      data.metaSocial.forEach(social => {
        if (social.socialNetwork === 'Facebook') {
          seo.facebookSocialNetwork = social;
        } else {
          seo.twitterSocialNetwork = social;
        }

      })
    }

    return Object.assign(seo, data);
  }
}

import React from "react";

import CustomLink from "../../../shared/custom-link/CustomLink";

export type BannerProps = {
  strapiBanner: {
    description: string;
    title: string;
    key_tags: string;
  }
}

const Banner = ({maxWidth, data}: {maxWidth: number} & {data: BannerProps}) => {
  return (
    <div className={`shadow-md p-3 rounded bg-white border border-gray-100 flex flex-col gap-2 max-w-[${maxWidth}px]`}>
      <div className="p-2 flex flex-col gap-2">
        <span className="font-bold text-lg">{data.strapiBanner.title}</span>
        <span>{data.strapiBanner.description}</span>
      </div>
      <CustomLink className={'mtr-btn-large mtr-btn-[secondary] mt-2'} isInternal to={'/kontakt/'} target={'_blank'}>
        <span className={'uppercase'}>Skontaktuj się z nami</span>
      </CustomLink>
    </div>
  )
}

export default Banner;

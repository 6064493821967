import React, {useContext} from "react";
import {GatsbyImage, IGatsbyImageData} from "gatsby-plugin-image";

import {GatsbyImageCustomType} from "../../../types/gatsby-image.type";
import './blog-article.style.scss'
import TextFormatter from "../../../utils/TextFormatter";
import PopularArticles from "../PopularArticles";
import SocialLinks from "../../shared/social-links/SocialLinks";
import Tags from "../../shared/tags/Tags";
import ArticlePaginator from "../ArticlePaginator";
import ReadMore from "../read-more/ReadMore";
import NofollowArticleMiddleware from '../../../utils/NofollowArticleMiddleware';
import {ArticleCardProps} from '../article-card/ArticleCard';
import {SEOInterface} from '../../../types/seo/SEO.type';
import {BannerContext} from "./banner/BannerHandlerContext";
import {SiteMetadataConfigProps} from "../../../types/metadata-config-props.type";
import {BannerProps} from "./banner/Banner";

export type BlogArticleProps = {
  strapiBlogArticle: {
    id: string,
    title: string,
    header: string,
    publishedAt: string,
    updatedAt: string,
    keywords?: string,
    readMore: { header: string }[]
    description: {
      data: {
        description: string;
      }
    };
    image: {
      alternativeText: string,
      localFile: {
        url: string,
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData,
        }
      }
    },
    users_permissions_user?: {
      username: string,
      userAvatar: GatsbyImageCustomType,
    },
    seo?: SEOInterface,
  },
  allStrapiBlogArticle?: {
    nodes: ArticleCardProps[]
  }
}

const BlogArticleComponent = (data: {article: BlogArticleProps & SiteMetadataConfigProps & BannerProps}) => {
  const bannerContext = useContext(BannerContext);

  if (bannerContext.setBannerData && !bannerContext.bannerData) {
    bannerContext.setBannerData({strapiBanner: data.article.strapiBanner});
  }

  const {strapiBlogArticle, allStrapiBlogArticle} = data.article;
  const {title, image, description, header, keywords, publishedAt} = strapiBlogArticle;
  const user = strapiBlogArticle.users_permissions_user;
  let article = description.data.description;
  const publishedAtDate = new Date(publishedAt).toLocaleDateString('pl-PL', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');
  article = TextFormatter.concatOrphans(article);
  article = NofollowArticleMiddleware(article);
  article = TextFormatter.replaceKeyWords(article, data.article.strapiBanner.key_tags.split(',').map(t => t.trim()));

  return(
    <>
      <article className={'md:px-12 lg:px-24 xl:px-36 pt-32 flex flex-col max-w-[1540px] mx-auto'}>
        <header className={'px-5 md:px-0 flex flex-col items-center text-center mb-12'}>
          <h1 className={'text-4xl lg:text-5xl md:font-semibold font-head text-black mb-5'}>{header}</h1>
          <div className={'flex flex-row items-center w-80 justify-between'}>
            {user &&
              <>
                <div className={'flex flex-row items-center'}>
                  <GatsbyImage className={'rounded-full mr-2.5'} alt={user.userAvatar.alternativeText}
                               image={user.userAvatar.localFile.childImageSharp.gatsbyImageData}/>
                  <p className={'text-sm text-neutrals-40'}>{user?.username}</p>
                </div>
                <div className={'w-px bg-neutrals-40 h-[28px]'}/>
              </>}
            <p className={'text-neutrals-40 text-sm'}>{publishedAtDate}</p>
          </div>
        </header>
        <section className={'flex flex-col mb-16'}>
          <GatsbyImage className={'mb-10 rounded'} alt={image.alternativeText} image={image.localFile.childImageSharp.gatsbyImageData}/>
          <div className={'flex flex-col lg:flex-row gap-5 px-5'}>
            <div className={'flex flex-col'}>
              <p className={' text-black font-head text-2xl font-semibold mb-6'} dangerouslySetInnerHTML={{__html: title}}/>
              <div className={'flex lg:hidden'}>
                <ReadMore data={strapiBlogArticle.readMore} />
              </div>
              <hr className={'border-neutrals-80'}/>
              <div className={'article'} dangerouslySetInnerHTML={{__html: article}}/>
              <div>
                <div className={'flex flex-col lg:hidden'}>
                  <hr className={'mb-10 border-neutrals-80 w-full'}/>
                  <Tags tags={keywords}/>
                </div>
                <hr className={'mb-5 mt-10 lg:mt-0 border-neutrals-80'}/>
                <SocialLinks/>
                <hr className={'mt-5 mb-4 border-neutrals-80'}/>
                <ArticlePaginator header={header}/>
              </div>
            </div>

            <div className={'hidden lg:flex flex-col gap-7 lg:w-1/3 shrink-0'}>
              <ReadMore data={strapiBlogArticle.readMore} />
              <Tags tags={keywords}/>
            </div>
          </div>
        </section>
      </article>
      <div className={'articles pb-14'}>
        <PopularArticles articles={allStrapiBlogArticle?.nodes || []}/>
      </div>
    </>
  )
}

export default BlogArticleComponent;

import React, {useEffect, createContext, ReactNode, useState} from "react";

import renderComponentAtEventLocation from "../../../shared/RenderComponentAtEventLocation";
import Banner, {BannerProps} from "./Banner";

const removeContainer = (container: HTMLDivElement | null, callback: () => void): void => {
  if (container && container.parentNode) {
    container.parentNode.removeChild(container);
    callback();
  }
}

interface IBannerContextState {
  setBannerData: React.Dispatch<React.SetStateAction<BannerProps | null>> | null,
  bannerData: BannerProps | null,
}

const bannerContextState: IBannerContextState = {
  setBannerData: null,
  bannerData: null,
}

const BannerContext = createContext<IBannerContextState>(bannerContextState);

const BannerContextProvider = ({ children }: { children: ReactNode }) => {
  const [bannerData, setBannerData] = useState<BannerProps | null>(null);

  useEffect(() => {
    if (!bannerData) {
      return;
    }

    const BANNER_WIDTH = 300;
    let container: HTMLDivElement | null = null;
    let leavedBanner: boolean | null = null;
    let leavedLink: boolean | null = null;
    let tagLeaveTimeout: NodeJS.Timeout | null = null;
    let bannerLeaveTimeout: NodeJS.Timeout | null = null;
    const tags = document.querySelectorAll('.key-tag');

    tags.forEach((tag, i) => {
      tag.addEventListener('mouseenter', (e) => {
        leavedLink = false;

        if (container && container.id === i + '') {
          return;
        }

        removeContainer(container, () => container = null);

        if (bannerLeaveTimeout) {
          clearTimeout(bannerLeaveTimeout);
        }

        if (tagLeaveTimeout) {
          clearTimeout(tagLeaveTimeout);
        }

        if (container) {
          removeContainer(container, () => container = null);
        }

        container = renderComponentAtEventLocation(e as MouseEvent, <Banner data={bannerData} maxWidth={BANNER_WIDTH}/>, i, BANNER_WIDTH);
        container.addEventListener('mouseleave', () => {
          leavedBanner = true;
          bannerLeaveTimeout = setTimeout(() => {
            if (leavedLink === null || leavedLink) {
              removeContainer(container, () => container = null);
            }
          }, 350);
        })
        container.addEventListener('mouseenter', () => {
          leavedBanner = false;
          if (tagLeaveTimeout) {
            clearTimeout(tagLeaveTimeout);
          }

          if (bannerLeaveTimeout) {
            clearTimeout(bannerLeaveTimeout);
          }
        });

      });
      tag.addEventListener('mouseleave', () => {
        leavedLink = true;
        tagLeaveTimeout = setTimeout(() => {
          if (leavedBanner === null || leavedBanner) {
            removeContainer(container, () => container = null);
          }
        }, 350);
      })
    })

  }, [bannerData]);
  return (
    <BannerContext.Provider value={{setBannerData, bannerData}}>
      { children }
    </BannerContext.Provider>
  )
}

export { BannerContext, BannerContextProvider };

import React, {ReactComponentElement} from 'react';
import {createRoot} from "react-dom/client";

const renderComponentAtEventLocation = (event: MouseEvent, component: ReactComponentElement<any>, id: number, elementWidth: number): HTMLDivElement => {
  const currentTarget = event.target as HTMLElement;
  const sourceElementRect = currentTarget.getBoundingClientRect();
  const container = document.createElement('div');
  container.style.position = 'absolute';

  const right = sourceElementRect.left + elementWidth;
  const middleOfElement = (elementWidth / 2) + (currentTarget.offsetWidth / 2);
  let containerLeft = sourceElementRect.left - (elementWidth / 2) + (currentTarget.offsetWidth / 2);

  if (right > document.body.clientWidth) {
    containerLeft = document.body.clientWidth - elementWidth;
  }

  if (sourceElementRect.left < middleOfElement) {
    containerLeft = 0;
  }

  const containerTop = sourceElementRect.bottom;

  container.id = id + '';
  container.style.left = `${containerLeft}px`;
  container.style.top = `${containerTop + document.documentElement.scrollTop}px`;

  const root = createRoot(container);
  root.render(component);

  document.body.appendChild(container);
  if (document.body.style.position !== 'relative') {
    document.body.style.position = 'relative';
  }

  return container;
};

export default renderComponentAtEventLocation;

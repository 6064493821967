import {GatsbyImageCustomType} from '../gatsby-image.type';

type SocialNetworkType = 'Facebook' | 'Twitter';

export class MetaSocial {
  title = '';
  description = '';
  image?: GatsbyImageCustomType
  socialNetwork: SocialNetworkType
}

export interface SEOInterface {
  metaTitle: string,
  metaDescription: string,
  metaImage: GatsbyImageCustomType,
  metaSocial?: MetaSocial[],
  keywords?: string,
  metaRobots?: string,
  structuredData?: object,
  metaViewport: string,
  canonicalURL: string,
}
